import React, { Component } from "react";
import Helmet from "react-helmet";
import Layout from "../../layout";
import config from "../../../data/SiteConfig";
import UK from "../../components/News/UK";

class UKPage extends Component {
  render() {
    return (
      <Layout>
        <div className="uk-news-container">
          <Helmet title={`UK Market and Business News | ${config.siteTitle}`} />
          <UK />
        </div>
      </Layout>
    );
  }
}

export default UKPage;
