import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import config from "../../../data/SiteConfig";


const UK = () => {
  const data = useStaticQuery(graphql`
    query UKNewsQuery {
      ukbusinessnews: allAirtable(limit:18
        filter: { table: { eq: "UKBusinessNews" } }
      ) {
        nodes {
          data {
            title
            url
            content
            source
            description
          }
          recordId
        }
      }
    }
  `);

  return (

        <div>

<div className="bg-white pt-10 pb-10 px-4 sm:px-6 lg:pt-10 lg:pb-10 lg:px-8">
  <div className="relative max-w-lg mx-auto lg:max-w-7xl">
    <div>
      <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
        UK Business News
      </h2>
      <p className="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
        The latest UK business news
      </p>
    </div>
    <div className="mt-12 grid gap-16 border-t-2 border-gray-100 pt-12">



      <ul className="space-y-12 lg:grid lg:grid-cols-1 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0">
        {data.ukbusinessnews.nodes.map((item, index) => (          
        <li key={index}>
          <a href={item.data.url} target="_blank">
          <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
            <div className="sm:col-span-2">
              <div className="space-y-4">
                <div className="text-lg leading-6 font-medium space-y-1">
                <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900 pt-2">
                    {item.data.title}
                </h3>
                    <p className="mt-6 text-sm leading-7 text-gray-500 mb-6">
                        Source {item.data.source}
                    </p>           
                  <p className="text-blue-600 pt-0">Read full story</p>
                </div>
              </div>
            </div>
          </div>
          </a>  
        </li>
        ))}

      </ul>



    </div>
  </div>

</div>



        </div>


  );
};

export default UK;